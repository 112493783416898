import { createReducer } from "@reduxjs/toolkit";
import {
  CATEGORIES_LOADING,
  CATEGORIES_ERROR,
  GET_CATEGORIES,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const categoriesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CATEGORIES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(CATEGORIES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_CATEGORIES, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(CREATE_CATEGORY, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(DELETE_CATEGORY, (state, { payload }) => {
      const updatedData = state.data.filter((item) => item.id !== payload.id);
      state.data = updatedData;
    })
    .addCase(EDIT_CATEGORY, (state, { payload }) => {
      const updatedData = payload;
      const updatedIndex = state.data.findIndex(
        (item) => item.id === updatedData.id
      );
      state.data[updatedIndex] = {
        ...state.data[updatedIndex],
        ...updatedData,
      };
    });
});

export default categoriesReducer;
