export const APP_CONFIG = "APP_CONFIG";

export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

///Users
export const SET_CURRENT_USERS = "SET_CURRENT_USERS";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";

/// nOTIFICATIONS
export const LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS";
export const LIST_NOTIFICATIONS_LOADING = "LIST_NOTIFICATIONS_LOADING";
export const LIST_NOTIFICATIONS_ERROR = "LIST_NOTIFICATIONS_ERROR";
export const INSERT_NEW_NOTIFICATION = "INSERT_NEW_NOTIFICATION";
export const UPDATE_UN_READ = "UPDATE_UN_READ";

//Projects
export const PROJECTS_LOADING = "PROJECTS_LOADING";
export const PROJECTS_ERROR = "PROJECTS_ERROR";
export const GET_PROJECTS = "GET_PROJECTS";
export const CREATE_PROJECT = "CREATE_PROJECT";

//Single Project
export const PROJECT_LOADING = "PROJECT_LOADING";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const GET_PROJECT = "GET_PROJECT";

//Requests
export const REQUESTS_LOADING = "REQUESTS_LOADING";
export const REQUESTS_ERROR = "REQUESTS_ERROR";
export const GET_REQUESTS = "GET_REQUESTS";
export const CREATE_REQUEST = "CREATE_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";

export const REQUEST_ERROR = "REQUEST_ERROR";
export const REQUEST_LOADING = "REQUEST_LOADING";
export const GET_REQUEST = "GET_REQUEST";

//Offers
export const OFFERS_LOADING = "OFFERS_LOADING";
export const OFFERS_ERROR = "OFFERS_ERROR";
export const GET_OFFERS = "GET_OFFERS";
export const CREATE_OFFER = "CREATE_OFFER";

//Under Review Offers
export const REVIEW_OFFERS_LOADING = "REVIEW_OFFERS_LOADING";
export const REVIEW_OFFERS_ERROR = "REVIEW_OFFERS_ERROR";
export const GET_REVIEW_OFFERS = "GET_REVIEW_OFFERS";

//In Progress Offers
export const PROGRESS_OFFERS_LOADING = "PROGRESS_OFFERS_LOADING";
export const PROGRESS_OFFERS_ERROR = "PROGRESS_OFFERS_ERROR";
export const GET_PROGRESS_OFFERS = "GET_PROGRESS_OFFERS";

//Bills
export const BILLS_LOADING = "BILLS_LOADING";
export const BILLS_ERROR = "BILLS_ERROR";
export const GET_BILLS = "GET_BILLS";
export const CREATE_BILL = "CREATE_BILL";
export const UPDATE_BILL = "UPDATE_BILL";

//Categories
export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
